.dropzone {
    height: 4rem;
    width: 100%;
    margin: 0.6rem 0rem;
    padding: 1rem;
    border: 1px dashed rgb(182, 182, 182);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.0rem;
    font-weight: 500;
    cursor: pointer;
  }

  .dropzone:hover {
    background-color: rgb(214, 255, 214);
  }
  
  .active {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    background-color: rgb(21, 88, 233);
    color: white;
    border: 1px solid rgb(182, 182, 182);
    border-radius: 5px;
  }